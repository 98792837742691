<template>
	<VOverlay
		class="login-overlay"
		:hasBackground="false"
		:hasJsonButton="false"
		:hasCloseButton="false"
		:closeOnBackgroundClick="false"
		v-model="overlayIsActive"
		:modalHasSharpCorner="true"
		:useFocusTrap="false"
		:closeWithEscape="false"
	>
		<template #header>
			<img class="has-margin-bottom-4" src="@assets/images/Logo.svg" />
		</template>

		<VTitle v-if="onDeviceEnrolmentStep" class="has-margin-bottom-3" :size="1">{{
			$t('deviceEnrollment.title')
		}}</VTitle>
		<VTitle v-else class="has-margin-bottom-3" :size="1">{{
			$t('login.title')
		}}</VTitle>

		<VNotification
			v-if="errorMessage"
			:text="errorMessage"
			:isFullwidth="false"
			:hasClose="false"
			type="danger"
		/>

		<div v-if="onLoginLevelZero" class="login-form-wrapper">
			<VField
				:isFloating="true"
				:label="$t('login.label.email')"
				:class="{ 'is-active': email }"
				:isError="emailError"
			>
				<VInput
					v-model="email"
					:placeholder="$t('login.label.email')"
					type="text"
					@keypress.enter.prevent="checkCredentials"
				/>
			</VField>
			<VField
				:isFloating="true"
				:label="$t('login.label.password')"
				:class="{ 'is-active': password }"
				:isError="passwordError"
			>
				<VInput
					v-model="password"
					:placeholder="$t('login.label.password')"
					type="password"
					name="password"
					@keypress.enter.prevent="checkCredentials"
				/>
			</VField>
			<RouterLink to="/request-password" active-class="is-active">
				{{ $t('login.forgotPassword') }}
			</RouterLink>
		</div>
		<div v-else>
			<TwoFAInput v-model:validModel="twoFAValid" :checkTwoFAFn="checkTwoFaAndlogin"  v-model:valueModel="twoFA" />
		</div>
		<template #footer>
			<VButton
				class="button-modal-footer"
				:text="buttonText"
				icon="chevron-right"
				@click="btnClicked"
				:iconOnRight="true"
				:disabled="buttonIsDisabled"
			/>
		</template>
	</VOverlay>
</template>

<script>
import { reactive, toRefs } from '@vue/reactivity';
import { computed } from 'vue'
import { onMounted, watch } from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { getStoreAction } from '@assets/scripts/store/config';
import useJWT from '@assets/scripts/composables/useJWT.js';
import Helpers from '@assets/scripts/helpers';

export default {
	name: 'LoginForm',
	setup() {
		const { t } = useI18n();
		const store = useStore();
		const { mfaEnabled, loginLevel } = useJWT();

		const state = reactive({
			email: '',
			password: '',
			twoFA: '',
			overlayIsActive: true,
			emailError: false,
			twoFAValid: false,
			passwordError: false,
			buttonIsDisabled: true,
			errorMessage: '',
		});

		/**
		 * Watch input values and disable the button when an input has no value.
		 *
		 * We do not have to compare it with the previous filled in values,
		 * because the watch will not get called if the value did not change.
		 */
		watch(
			() => [state.email, state.password, state.twoFA],
			([newEmail, newPassword, newTwoFA]) => {
				if (newEmail) state.emailError = false;
				if (newPassword) state.passwordError = false;

				if (loginLevel.value === 0) {
					state.buttonIsDisabled = !newEmail || !newPassword;
				} else {
					state.buttonIsDisabled = newTwoFA.length !== 6;
				}
			}
		);

		// Check if inputs are automatically filled in when the browser remembered it
		onMounted(() => {
			const inputsFilledIn =
				state.email.length > 0 && state.password.length > 0;

			if (inputsFilledIn) {
				state.buttonIsDisabled = false;
			}
		});

		// check combination of the email and password
		const checkCredentials = () => {
			if (state.email.length < 1) {
				// if no email show error
				state.emailError = true;
				state.errorMessage = t('login.noEmail');
			} else if(!Helpers.validateEmail(state.email)) {
				// if email not valid show error
				state.emailError = true;
				state.errorMessage = t('login.noValidEmail');
			} else if (state.password.length < 1) {
				// if no password show error
				state.passwordError = true;
				state.errorMessage = t('login.noPassword');
			} else {
				state.errorMessage = '';
				store
					.dispatch(getStoreAction('LOGIN'), {
						email: state.email,
						password: state.password,
					})
					.then(() => {
						// success, no further action needed
						state.errorMessage = '';
						state.buttonIsDisabled = true;
					})
					.catch((error = false) => {
						// show error in form
						state.errorMessage = error;
					});
			}
		};
		// login after checking twoFA code 
		const checkTwoFaAndlogin = () => {
			// if no twoFA code show error
			if (!state.twoFAValid) {
				state.errorMessage = t('login.noTwoFA');
			} else {
				state.errorMessage = '';

				store
					.dispatch(getStoreAction('LOGIN_TWO_F_A'), {
						twoFA: state.twoFA,
					})
					.then(() => {
						// success, no further action needed
						state.errorMessage = '';
					})
					.catch((error = false) => {
						// show error in form
						state.errorMessage = error;
					});
			}
		};

		const onDeviceEnrolmentStep = computed(() => {
			return !mfaEnabled.value && loginLevel.value === 1;
		});
		const onLoginLevelZero = computed(() => {
			return loginLevel.value === 0;
		});
		const buttonText = computed(() => {
			if(onLoginLevelZero.value) {
				return t('login.nextButton');
			} else {
				return t('login.button');
			}
		});

		const btnClicked = () =>{
			if(onLoginLevelZero.value){
				checkCredentials();
			} else{
				checkTwoFaAndlogin();
			}
		}

		return {
			...toRefs(state),
			checkTwoFaAndlogin,
			checkCredentials,
			loginLevel,
			mfaEnabled,
			onDeviceEnrolmentStep,
			onLoginLevelZero,
			buttonText,
			btnClicked,
		};
	},
};
</script>
